import logo from './logo.svg';
import { Routes, Route, Link } from "react-router-dom";
import router from './router';
import './App.css';

function App() {
  return (
    <div className="App">
      
      <Routes>
            {
              router.map(item => {
                return (
                  <Route key={item.path} path={item.path} exact={true} element={item.component} />
                )
              })
            }
          </Routes>
    </div>
  );
}

export default App;
