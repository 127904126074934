/* eslint-disable import/no-anonymous-default-export */


import Home from '../pages/index'

export default [
    {
        path: '/',
        component: <Home/>
    }
]