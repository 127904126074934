import logo from '../assets/images/logo.svg'
import x from '../assets/images/x.svg'
import tg from '../assets/images/tg.svg'
import dc from '../assets/images/discord.svg'
import card from '../assets/images/card.svg'
import slogan from '../assets/images/slogan.svg'
import chrome from '../assets/images/chrome.jpg'
import logoicon from '../assets/images/logo.png'
import './index.scss'
export default () => {
    return (
        <div className="w100 xbot flex flex-column">
            <div className="header flex p-22 p-l-56 flex-center flex-between m-p-l-20 m-flex-column m-gap-20">
                <span className='flex flex-center gap-40 m-gap-20 m-w100 m-flex-between'>
                <img src={logo} alt="" className='m-w-100' />
                <a className='cf fz-16 m-fz-14 normal-a' target="_blank" href="https://kepler.homes">Home</a>
                 <a className='cf fz-16 m-fz-14 normal-a' target="_blank" href="https://borrow.xbot.bi">Borrow</a>
                 <a className='cf fz-16 m-fz-14 normal-a' target="_blank" href="https://airdrop.xbot.bi">Airdrop</a>
                 <a className='cf fz-16 m-fz-14 normal-a' target="_blank" href="https://docs.xbot.bi">Docs</a>
                 <a className='cf fz-16 m-fz-14 normal-a' target="_blank" href="https://app.xbot.bi">WebAPP</a>
                </span>
                <span className='flex flex-center gap-16 m-gap-10'>
                    <a className="icon-com flex flex-center flex-middle " target="_blank" href="https://twitter.com/KeplerHomes">
                        <img src={x} alt="" />
                    </a>
                    <a className="icon-com flex flex-center flex-middle" target="_blank" href="https://t.me/KeplerHomess">
                        <img src={tg} alt="" />
                    </a>
                    <a className="icon-com flex flex-center flex-middle" target="_blank" href="https://discord.gg/tFCMng5Mhd">
                        <img src={dc} alt="" />
                    </a>
                </span>
                 
            </div>
            <div className="flex flex-1 flex-center flex-middle ">
            <div className="content flex flex-middle gap-143 m-flex-column m-flex-center m-gap-50 p-15">
                <img src={card} alt="" className='m-w-200 m-m-t-20' />
                <div className="flex flex-column flex-center gap-40 m-p-b-100">
                    <div className="cf slogan-name fz-56 m-fz-40">Explore Power for your xBot <br/>
                    <span className='cr'>Take profits on Twitter</span></div>
                    <div className="ta cf fz-20 slogan m-fz-16">Install the extension and receive your xBot directly on the Twitter interface. Continuously recharge your xBot to earn profits.</div>
                    <div className="flex flex-wrap gap-15 flex-middle">
                    <a href="https://chromewebstore.google.com/detail/xbot/lpeakpblpiemdidnflafffbihcjdoodo" target="_blank">
                        <img src={chrome} alt="" className='pointer' />
                    </a>
                    <a href="https://app.xbot.bi" target="_blank" className='launch flex flex-center'>
                        <img src={logoicon} alt="" className='w-30' />
                        Launch App
                    </a>
                    </div>
                    

                </div>

            </div>
            </div>
            
            <div className="footer cf flex flex-around m-flex-column fz-16 ta p-b-40 m-fz-14">
               
            Copyright 2024 | Powered by KeplerHomes
            <div className="flex flex-center flex-middle cf m-m-t-20">
                  <a href="https://docs.xbot.bi/legal-stuff/borrow-policy" className='normal-a cf06' target="_blank">Borrow Policy</a> ｜ 
                  <a href="https://docs.xbot.bi/legal-stuff/privacy-policy" className='normal-a cf06' target="_blank">Privacy Policy</a>｜ 
                  <a href="https://docs.xbot.bi/legal-stuff/term-of-use" className='normal-a cf06' target="_blank">Terms of use</a>  
                </div>
            </div>
        </div>
    )
}